<template>
    <div class="page">
      <ClientPromocodes :userId="$route.params.id" userType='CorpClient'/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientPromocodes from '@/components/clients/profile/ClientPromocodes';

export default {
  name: 'ClientPromocodesPage',
  components: {
    ClientPromocodes,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
